const devUrl = 8027
// const devUrl = "80";
// const proUrl = '/api'
const proUrl = ''
var devSocketUrl = devUrl
var proSocketUrl = proUrl


export default {
    loginUrl: '/login',
    adminUrl: '/admin',
    uploadFileApi: '/sysFile/UploadFile.do',
    versionApi: '/webVersion.txt',
    versionCode: '2024年12月11日 14:58:00',
    apiUrl: process.env.NODE_ENV != 'production' ? devUrl : proUrl,
    socketUrl: process.env.NODE_ENV != 'production' ? devSocketUrl : proSocketUrl,
    sysName: '默认显示系统名称',
    // productionGzip: true,
    // vueTagData: {},
    // productionGzipExtensions: ['js', 'css'],
}
